import { graphql, HeadProps, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
import OfferCardGrid from '../../components/offer-card-grid/offer-card-grid'
import { Offer } from '../../contracts/offer'
import { useMembershipStore } from '../../stores/membership-store'

export const Head = ({ pageContext }: HeadProps<{}, { category: string }>) => {
  return (
    <title>{pageContext.category?.replace(/-/g, ' ')} Offers - Disco Deals</title>
  )
}
export interface CategoryItemProps {
  pageContext: {
    category: string
  },
  data: any
}

const EmptyState = ({ categoryName }: { categoryName: string }) => <div className='max-w-[300px] mx-auto text-center'>
    <h2 className="text-lg font-medium text-gray-900">You don't have any {categoryName} offers</h2>
    <p className="mt-1 text-sm text-gray-500"><Link to='/memberships/choose' className='text-primary-600 hover:underline'>Review your memberships</Link> to discover all discounts available to you.</p>
  </div>

export default ({ data: { category, allOffer } }: CategoryItemProps) => {
  const image = getImage(category.image)

  // Display offers for user
  const membershipOffers = useMembershipStore(state => state.membershipOffers)
  const [userOffersState, setUserOffersState] = useState<Offer[]>()

  useEffect(() => {
    if (!membershipOffers) {
      setUserOffersState(undefined)
      return
    }

    const offers = membershipOffers.filter(offer => offer.category.toLowerCase() === category.name.toLowerCase())
    setUserOffersState(offers)
  }, [membershipOffers])

  const userOffers = userOffersState ?? membershipOffers?.filter(offer => offer.category.toLowerCase() === category.name.toLowerCase())
  
  return (
    <main className='py-4 container'>
      {userOffers &&
        <div className='mb-8'>
          <div>
            <h2 className='text-2xl mb-6 flex items-center font-semibold'>
              {image &&
                <GatsbyImage
                  image={image!}
                  alt={category.name.replace(/-/g, ' ')}
                  objectFit='cover'
                  className="category-image"
                />
              }
              Your {category.name.replace(/-/g, ' ')} Offers
            </h2>

            {userOffers.length !== 0 && 
              <OfferCardGrid offers={userOffers} />
            }
            {userOffers.length === 0 &&
              <EmptyState categoryName={category.name.replace(/-/g, ' ')} />
            }
          </div>
        </div>
      }

      <div>
        <h2 className='text-2xl mb-6 flex items-center font-semibold'>
          {image && !userOffers &&
            <GatsbyImage
              image={image!}
              alt={category.name}
              objectFit='cover'
              className="category-image"
            />
          }
          All {category.name.replace(/-/g, ' ')} Offers
        </h2>

        <OfferCardGrid offers={allOffer.edges.map(edge => edge.node)} />
      </div>
    </main>
  )
}

export const query = graphql`
query($category: String) {
  category(id: { eq: $category }) {
    id
    name
    image {
      childImageSharp {
        gatsbyImageData(width: 800)
      }
    }
  }
  allOffer(filter: {category: {eq: $category}}) {
    edges {
      node {
        ...OfferDisplay
      }
    }
  }
}

`